import React, { useState } from 'react'
import config from 'kiss/config'

import { like, unlike } from 'kiss/modules/project-page/campaign/news/redux'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { getRouteFor, SIGN_IN } from 'kiss/routes/redux'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getProjectSlug,
  isLogged,
} from 'kiss/modules/project-page/page-state/selectors'

import flow from 'lodash/fp/flow'
import orderBy from 'lodash/fp/orderBy'
import PropTypes from 'prop-types'

import {
  AlertBox,
  BubbleIconNext,
  ContentCard,
  FlexWrapper,
  HeartIconNext,
  LockOutlineIcon,
  mq,
  parseHtml,
  pxToRem,
  Text,
  TextButton,
} from '@kisskissbankbank/kitten'
import { Displayer } from '@kisskissbankbank/underwood'

import CommentForm from 'kiss/modules/project-page/common/news/CommentForm'
import CommentList from 'kiss/modules/project-page/campaign/news/components/comments-list'
import LockedNews from 'kiss/modules/project-page/common/news/LockedNews'
import NoResult from 'kiss/modules/project-page/common/news/NoResult'
import LoadMore from 'kiss/modules/project-page/common/news/LoadMore'

import classNames from 'classnames'
import styled from 'styled-components'

const StyledHeartIconNext = styled(HeartIconNext)`
  &.kiss-Heart--active path {
    fill: var(--color-danger-500) !important;
  }
`

const StyledVisibilityMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${pxToRem(10)};
  color: var(--color-primary-500);

  svg {
    flex-shrink: 0;
  }

  @media ${mq.mobile} {
    flex: 1 0 min-content;

    span {
      flex-basis: min-content;
    }
  }
`

const NewsCard = ({ newsItem = undefined, locale = 'fr' }) => {
  const {
    id,
    currentUserHasLiked,
    likesCount,
    commentsConnection,
    visibleForCurrentUser = false,
    visibility,
    rewardsConnection,
  } = newsItem

  const t = useTranslation()
  const { viewportIsSOrLess } = useMediaQuery()

  const [isLoading, setIsLoading] = useState(false)
  const [isShowingCommentSection, setIsShowingCommentSection] = useState(false)
  const [isShowingAlert, setIsShowingAlert] = useState(false)

  const date = new Date(newsItem.publicationAt)
  const dateFormated = date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
  const slug = useSelector(getProjectSlug)

  const isCurrentUserLoggedIn = useSelector(isLogged)
  const userCanSeeNews =
    visibleForCurrentUser || (!isCurrentUserLoggedIn && visibility === 'public')
  const routeFor = useSelector(getRouteFor)

  const dispatch = useDispatch()

  const likeOrUnlike = async () => {
    if (!userCanSeeNews) return
    if (!isCurrentUserLoggedIn) {
      setIsShowingAlert(true)
      return
    }

    setIsLoading(true)

    await (currentUserHasLiked
      ? dispatch(unlike({ newsId: id, slug }))
      : dispatch(like({ newsId: id, slug })))
    setIsLoading(false)
    document.activeElement.blur()
  }

  const getLastCommentCursor = () => {
    if (!commentsConnection?.edges?.length) {
      return null
    }

    const lastComment = commentsConnection?.edges?.slice(-1)
    return lastComment?.[0]?.cursor
  }

  return (
    <ContentCard
      className="k-u-margin-bottom-triple"
      key={`news-${newsItem.slug}`}
    >
      <ContentCard.Header>
        <div className="k-u-flex k-u-flex-justifyContent-sb k-u-flex-gap-single">
          <div className="k-u-flex-shrink-none">{dateFormated}</div>
          {['contributors', 'rewards'].includes(visibility) && (
            <StyledVisibilityMessage>
              <LockOutlineIcon color="currentColor" width={16} height={16} />
              <Text>
                {visibility === 'contributors'
                  ? t('comments.locked.news.label', { parseHtml: true })
                  : t('comments.locked.news.rewards.label', {
                      parseHtml: true,
                    })}
              </Text>
            </StyledVisibilityMessage>
          )}
        </div>
      </ContentCard.Header>
      <ContentCard.Title>{parseHtml(newsItem.title)}</ContentCard.Title>
      <ContentCard.Content>
        {userCanSeeNews ? (
          <Displayer
            text={newsItem.content}
            perfEnabled
            useRichTextStyle
            configResponsiveImageHandler={
              config[APP_ENV].responsiveImageHandler
            }
          />
        ) : (
          <LockedNews
            rewardsVisibility={rewardsConnection?.edges}
            visibility={visibility}
          />
        )}
      </ContentCard.Content>
      {userCanSeeNews && (
        <ContentCard.Footer>
          <FlexWrapper
            className="k-u-flex-alignItems-baseline k-u-flex-wrap-wrap k-u-flex-justifyContent-sb"
            gap={[10, 30]}
            direction="row"
          >
            <FlexWrapper
              className="k-u-flex-alignItems-baseline k-u-weight-500"
              gap={30}
              direction="row"
            >
              <TextButton
                size="large"
                onClick={likeOrUnlike}
                disabled={isLoading}
              >
                <StyledHeartIconNext
                  className={classNames({
                    'kiss-Heart--active': currentUserHasLiked,
                  })}
                />
                {viewportIsSOrLess && likesCount}
                {!viewportIsSOrLess &&
                  t('project.comments.total_like', {
                    count: likesCount || 0,
                  })}
              </TextButton>
              <TextButton
                size="large"
                onClick={() =>
                  setIsShowingCommentSection(!isShowingCommentSection)
                }
              >
                <BubbleIconNext />
                {t('project.comments.total_comments', {
                  count: commentsConnection?.totalCount || 0,
                })}
              </TextButton>
            </FlexWrapper>
          </FlexWrapper>
        </ContentCard.Footer>
      )}
      {!isCurrentUserLoggedIn && isShowingAlert && (
        <div className="k-u-margin-top-singleHalf">
          <AlertBox displayIcon>
            <a href={routeFor(SIGN_IN)}>
              {t('project.news.signin__link.link.label')}
            </a>{' '}
            <span>{t('project.news.signin__link.text')}</span>
          </AlertBox>
        </div>
      )}

      {userCanSeeNews && (
        <>
          <CommentForm
            newsId={id}
            onSubmit={() =>
              !isShowingCommentSection ? setIsShowingCommentSection(true) : null
            }
          />
          {isShowingCommentSection && (
            <div className="k-u-margin-top-double">
              <CommentList
                newsId={id}
                comments={flow(orderBy(['publicationAt'], ['asc']))(
                  commentsConnection.edges.map((edge) => edge.node),
                )}
              />
              <LoadMore
                newsId={id}
                newsCommentsTotal={commentsConnection?.totalCount}
                newsComments={commentsConnection?.edges}
                lastCursor={getLastCommentCursor()}
              />
              <NoResult
                total={commentsConnection?.totalCount}
                message={t('project.news.comments.no_result')}
              />
            </div>
          )}
        </>
      )}
    </ContentCard>
  )
}

export default NewsCard

NewsCard.propTypes = {
  newsItem: PropTypes.object,
  locale: PropTypes.string,
}
