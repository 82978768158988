import React from 'react'

export const RocketCircleIcon = ({
  circleColor = '#fff',
  illustrationColor = '#333',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <circle fill={circleColor} cx="10" cy="10" r="10" />
      <path
        fill={illustrationColor}
        transform="translate(3.5 4) rotate(30 7 9) scale(.70)"
        d="M9 15c0 .667-.667 1.667-2 3-1.333-1.333-2-2.333-2-3h4zM2.282 9.87c.156.934.378 1.986.664 3.158l.055.22L0 14c.737-1.944 1.498-3.32 2.282-4.13zm9.44.002c.737.764 1.455 2.031 2.151 3.8L14 14l-3.002-.75.174-.707a40.46 40.46 0 00.55-2.671zM7 0c2 3.285 3 5.619 3 7 0 1.381-.333 3.381-1 6H5c-.667-2.65-1-4.65-1-6 0-1.35 1-3.683 3-7zm0 5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
      />
    </svg>
  )
}
