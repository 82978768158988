import React from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'kiss/hooks/use-translation'

import { getAssetFor } from 'kiss/assets/redux'

import Challenge from './challenge'
import {
  Button,
  Container,
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  Grid,
  GridCol,
  Marger,
  pxToRem,
  ScreenConfig,
  stepToRem,
  Text,
  Title,
} from '@kisskissbankbank/kitten'

import styled, { keyframes } from 'styled-components'

const StyledBackground = styled.div`
  padding: ${pxToRem(50)} 0;
  background-size: cover;
  background-color: var(--color-coral-100);
  overflow: hidden;
  position: relative;
  z-index: 0;

  @media (min-width: ${ScreenConfig.S.min}px) {
    padding: ${pxToRem(80)} 0;
  }

  @media (min-width: ${ScreenConfig.L.min}px) {
    padding: ${pxToRem(100)} 0;
  }
`

const backgroundAnimation = () =>
  keyframes`
  0% {
    background-position-y: 0;
  }
  100% {
    background-position-y: ${pxToRem(1200)};
  }
`

const StyledHeader = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: repeat;
  background-size: cover;
  animation-name: ${backgroundAnimation};
  animation-duration: 150s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  width: 150%;
  height: 200%;
  position: absolute;
`

const StyledContainer = styled.div`
  position: relative;
  padding: ${pxToRem(40)} ${pxToRem(CONTAINER_PADDING_THIN)};
  background-color: var(--color-grey-000);

  @media (min-width: ${ScreenConfig.S.min}px) {
    padding: ${pxToRem(50)} ${pxToRem(CONTAINER_PADDING)};
  }
`

const StyledParagraph = styled(Text)`
  font-size: ${stepToRem(0)};
  line-height: 1.6;

  @media (min-width: ${ScreenConfig.S.min}px) {
    font-size: ${stepToRem(2)};
  }
`
const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HIDDEN_CHALLENGE = 'conseillers-lbp'

const ULULE_LINK = 'https://fr.ulule.com/projects/create/?partner=kkbb'

const HeaderWithBackground = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)
  const { challenge } = useParams()

  return (
    <StyledBackground>
      <StyledHeader
        backgroundImage={assetFor('maker/fund_my_project/hero.jpg')}
      />
      <Container>
        <Grid>
          <GridCol col-l="8" offset-l="2">
            <StyledContainer>
              <StyledLogoContainer>
                <img
                  height={75}
                  src={assetFor('logos/KissKiss_Ulule.svg')}
                  alt={'Logos KissBankbank Ulule'}
                />
              </StyledLogoContainer>

              <Marger bottom={{ default: 1, fromS: 2 }}>
                {challenge && challenge !== HIDDEN_CHALLENGE && (
                  <Challenge challenge={challenge} />
                )}
                <Title
                  tag="h1"
                  family="antiqueolive"
                  modifier="tertiary"
                  className="k-u-align-center"
                >
                  {t('fund_my_project.hero.title', {
                    parseHtml: true,
                  })}
                </Title>
              </Marger>

              <Marger top={{ default: 1, fromS: 2 }} bottom="4">
                <StyledParagraph
                  tag="div"
                  color="font1"
                  className="k-u-align-center"
                  weight="400"
                >
                  {t('fund_my_project.header.paragraph', {
                    parseHtml: true,
                  })}
                </StyledParagraph>
              </Marger>

              <Marger top="4" className="k-u-align-center">
                <Button
                  size="large"
                  modifier="helium"
                  as="a"
                  href={ULULE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('fund_my_project.header.ulule_link')}
                </Button>
              </Marger>
            </StyledContainer>
          </GridCol>
        </Grid>
      </Container>
    </StyledBackground>
  )
}

export default HeaderWithBackground
