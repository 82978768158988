import { HEADER_HEIGHT } from '@kisskissbankbank/kitten'
import Alert from 'kiss/app/alerts/components/alert'
import MasqueradeAlert from 'kiss/app/alerts/components/masquerade-alert'
import {
  ALERTS_ID,
  getAlerts,
  getScroll,
  resetScrollValue,
} from 'kiss/app/alerts/redux'
import { masquerading as masqueradingSelector } from 'kiss/session/redux'
import { scrollTo } from 'kiss/utils/animation/scroll-to'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertFacebookEmail from './components/alert-facebook-email'
import KycAlert from './components/kyc-alert'
import { AlertsContext } from './context'

const Alerts = () => {
  const dispatch = useDispatch()
  const alerts = useSelector(getAlerts)
  const scroll = useSelector(getScroll)
  const masquerading = useSelector(masqueradingSelector)
  const alertsCtx = useContext(AlertsContext)

  useEffect(() => {
    if (!scroll) return
    scrollTo(document.getElementById(ALERTS_ID), 500, {
      offset: -1 * HEADER_HEIGHT,
    })
    dispatch(resetScrollValue())
  })

  return (
    <div id={ALERTS_ID}>
      {masquerading && <MasqueradeAlert />}
      <KycAlert />

      {[...alerts, ...alertsCtx.alerts].map((alertItem) => (
        <Alert
          key={alertItem.uuid}
          uuid={alertItem.uuid}
          message={alertItem.message}
          type={alertItem.type}
        />
      ))}
      <AlertFacebookEmail />
    </div>
  )
}

export default Alerts
