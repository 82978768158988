import React from 'react'
import { Modal, Text, Button } from '@kisskissbankbank/kitten'
import PropTypes from 'prop-types'
import { useTranslation } from 'kiss/hooks/use-translation'

const MobileFilter = ({
  children,
  headerLabel,
  footerActionLabel,
  onClose = () => {},
  onReset = () => {},
  onApply = () => {},
}) => {
  const t = useTranslation()

  return (
    <Modal onClose={onClose} isOpen>
      <Modal.Title>
        {headerLabel}
        <br />
        <Text
          tag="button"
          type="button"
          size="small"
          weight="500"
          className="k-u-reset-button k-u-link k-u-link-primary1"
          onClick={onReset}
        >
          {t('project.news.filters.filter.reset')}
        </Text>
      </Modal.Title>
      <Modal.Content align="left">
        <div>{children}</div>

        <Modal.Actions>
          <Button type="button" modifier="helium" onClick={onApply}>
            {footerActionLabel}
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  )
}

MobileFilter.propTypes = {
  headerLabel: PropTypes.node.isRequired,
  footerActionLabel: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  onReset: PropTypes.func,
  onApply: PropTypes.func,
}

export default MobileFilter
