import { RichText } from '@kisskissbankbank/kitten'
import PropTypes from 'prop-types'
import React from 'react'
import RichTextParagraph from '../rich-text/paragraph'
import RichTextTitle from '../rich-text/title'
import RichTextUnorderedList from '../rich-text/unordered-list'
import RichTextImage from '../rich-text/image'

const RichContentText = ({
  content,
  modifiers = {
    h1: 'secondary',
    h2: 'tertiary',
    h3: 'quaternary',
    h4: 'quinary',
  },
  paragraphProps = {},
  ...props
}) => {
  return (
    <RichText>
      {content?.map((block, index) => {
        switch (block?.type) {
          case 'heading':
            return (
              <RichTextTitle
                key={index}
                fromRichText
                modifiers={modifiers}
                {...block}
                {...props}
              />
            )

          case 'paragraph':
            return (
              <RichTextParagraph
                key={index}
                {...paragraphProps}
                {...block}
                {...props}
              />
            )

          case 'unordered_list':
            return <RichTextUnorderedList key={index} {...block} {...props} />

          case 'image':
            return <RichTextImage key={index} {...block} {...props} />

          default:
            console.warn(`No block at position ${index}`, content)
            return null
        }
      })}
    </RichText>
  )
}

RichContentText.propTypes = {
  content: PropTypes.array.isRequired,
  modifiers: PropTypes.object,
  paragraphProps: PropTypes.object,
}

export default RichContentText
