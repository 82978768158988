import React from 'react'
import PropTypes from 'prop-types'
import { domElementHelper, Button } from '@kisskissbankbank/kitten'
import { popupCenterPosition } from 'kiss/components/social/social-click-handler'

const SocialButton = ({
  children,
  url = '',
  name = '',
  body = '',
  subject = '',
  customHandleClick,
}) => {
  const handleClick = () => {
    if (!domElementHelper.canUseDom()) return

    const width = 560
    const height = 480
    const { top, left } = popupCenterPosition(width, height)

    window.open(
      url,
      `Sharer ${name}`,
      'resizable=yes,scrollbars=yes,titlebar=no' +
        `,width=${width},height=${height}` +
        `,top=${top},left=${left}`,
    )
  }

  const buildEmailRef = () => {
    let ref = `mailto:?subject=${encodeURIComponent(subject)}`
    if (body) {
      ref += `&body=${encodeURIComponent(body)}`
    }
    return ref
  }

  return body && subject ? (
    <Button
      as="a"
      href={buildEmailRef()}
      fit="icon"
      borderRadius={50}
      modifier="beryllium"
    >
      {children}
    </Button>
  ) : (
    <Button
      onClick={customHandleClick ? customHandleClick : handleClick}
      fit="icon"
      borderRadius={50}
      modifier="beryllium"
    >
      {children}
    </Button>
  )
}

SocialButton.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  body: PropTypes.string,
  subject: PropTypes.string,
  customHandleClick: PropTypes.func,
}

export default SocialButton
