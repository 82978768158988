import React from 'react'
import PropTypes from 'prop-types'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { Button } from '@kisskissbankbank/kitten'

const LoadMoreButton = ({
  loading = false,
  label = '',
  onClick = () => {},
  ...props
}) => (
  <Button
    size="small"
    type="button"
    modifier="helium"
    onClick={onClick}
    {...props}
  >
    {loading ? <LoadingAnimation color="var(--color-grey-000)" /> : label}
  </Button>
)

LoadMoreButton.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
}

export default LoadMoreButton
