import React from 'react'

import Proptypes from 'prop-types'

import classNames from 'classnames'

import { Baseline, ListItems, Partners, Legal } from './components'
import { CreatedWith } from './components/created-with'

import { StyledFooter } from './styles'
import styled from 'styled-components'

const StyledFooterContainer = styled.div`
  @media (min-width: 1440px) {
    margin: auto;
  }
`

const StyledListItemsContainer = styled.div`
  max-width: 1360px;
  margin: auto;
`

const Footer = ({ minimalist = false, keepTopMargin = false }) => {
  if (minimalist) {
    return (
      <StyledFooter
        className={classNames('k-u-padding-top-none', {
          'k-u-margin-top-quintuple k-u-margin-top-decuple@s-up': keepTopMargin,
        })}
      >
        <CreatedWith />
        <Partners />

        <Legal />
      </StyledFooter>
    )
  }
  return (
    <StyledFooter
      className={
        keepTopMargin && 'k-u-margin-top-quintuple k-u-margin-top-decuple@s-up'
      }
    >
      <StyledFooterContainer>
        <Baseline />
        <StyledListItemsContainer>
          <ListItems />
        </StyledListItemsContainer>
        <Partners />
        <Legal />
      </StyledFooterContainer>
    </StyledFooter>
  )
}

Footer.Proptypes = {
  keepTopMargin: Proptypes.bool,
  minimalist: Proptypes.bool,
}

export default Footer
