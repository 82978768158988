import React from 'react'
import PropTypes from 'prop-types'

const RichTextImage = ({ url, className, alt = '' }) => {
  return <img className={className} src={url} alt={alt} />
}

RichTextImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

export default RichTextImage
