import {
  HeaderNav,
  KissKissBankBankLogo,
  Overlay,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { getRouteFor, HOMEPAGE } from 'kiss/routes/redux'
import { isLogged as isLoggedSelector } from 'kiss/session/redux'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import BurgerMenu from './components/burger-menu'

import Navigation from './components/navigation'
import SearchButton from './components/search-button'
import UserLoggedIn from './components/user-logged-in'
import UserLoggedOut from './components/user-logged-out'

const HEADER_NAV_ID = 'KissKiss__appHeader'

const ScreenCentered = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`

const Header = ({
  navigation = true,
  withoutBurger = false,
  withoutRight = false,
  tinyLogo = false,
  children,
  isSticky = null,
  loggedInBonusComponent = null,
  loggedOutBonusComponent = null,
}) => {
  const t = useTranslation()
  const [stickyProps, setStickyProps] = useState({ top: 0 })
  const routeFor = useSelector(getRouteFor)
  const isLogged = useSelector(isLoggedSelector)
  const { viewportIsSOrLess } = useMediaQuery()

  useEffect(() => {
    if (isSticky) {
      setStickyProps({ top: 0, isSticky })
    }
  }, [])

  return (
    <>
      <Overlay zIndex={Z_INDEX.global.headerWithOpenMenu} position="fixed" />
      <HeaderNav
        id={HEADER_NAV_ID}
        isLogged={isLogged}
        isFixed={false}
        quickAccessProps={{
          href: '#main',
          text: t('layouts.header.quicklink'),
          zIndex: Z_INDEX.global.quickAccessLink,
        }}
        stickyProps={stickyProps}
        zIndexConfig={{
          headerWithOpenMenu: Z_INDEX.global.headerWithOpenMenu,
          header: Z_INDEX.global.header,
        }}
      >
        {!withoutBurger && (
          <HeaderNav.BurgerMenu
            dropdownContentWidth={230}
            className="k-u-hidden@l-up"
          >
            <BurgerMenu />
          </HeaderNav.BurgerMenu>
        )}

        <HeaderNav.Logo
          href={routeFor(HOMEPAGE)}
          aria-label={t('layouts.header.go_to_kisskissbankbank_homepage')}
          className={
            withoutBurger &&
            'k-u-margin-left-single k-u-margin-left-triple@s-up'
          }
        >
          {tinyLogo || (isLogged && viewportIsSOrLess) ? (
            <KissKissBankBankLogo aria-hidden small height={6} />
          ) : (
            <KissKissBankBankLogo aria-hidden height="25" />
          )}
        </HeaderNav.Logo>
        {navigation && (
          <HeaderNav.Nav className="k-u-hidden@m-down">
            <Navigation />
          </HeaderNav.Nav>
        )}
        {children && <ScreenCentered>{children}</ScreenCentered>}
        {!withoutRight && (
          <HeaderNav.Right>
            <SearchButton />

            <UserLoggedIn bonusComponent={loggedInBonusComponent} />

            <UserLoggedOut bonusComponent={loggedOutBonusComponent} />
          </HeaderNav.Right>
        )}
      </HeaderNav>
    </>
  )
}

Header.propTypes = {
  navigation: PropTypes.bool,
  withoutBurger: PropTypes.bool,
  withoutRight: PropTypes.bool,
  tinyLogo: PropTypes.bool,
  loggedInBonusComponent: PropTypes.node,
  loggedOutBonusComponent: PropTypes.node,
}

export default Header
