import React from 'react'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'
import NumberFormat from 'react-number-format'
import { Field } from '@kisskissbankbank/kitten'
import { parse, isValid } from 'date-fns'
import { useTranslation } from 'kiss/hooks/use-translation'

const isValidDate = (date) => {
  const parsed = parse(date, 'dd/MM/yyyy', new Date())
  return isValid(parsed)
}

const REGEX_DD_MM_YYYY =
  /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/

const DateInput = ({
  name,
  style = {},
  className = '',
  defaultValue = undefined,
  disabled = false,
  validate = () => {},
  ...props
}) => {
  const t = useTranslation()
  const [field, meta, { setValue }] = useField({
    name,
    validate: (value) => {
      if (!isValidDate(value)) {
        return t('app.forms.error.invalid_date')
      }

      if (!REGEX_DD_MM_YYYY.test(value)) {
        return t('app.forms.error.invalid_date_format', {
          format: t('app.forms.date_format'),
        })
      }

      if (validate) {
        validate(value)
      }
    },
  })

  const handleChange = ({ value }) => {
    const day = value.substring(0, 2)
    const month = value.substring(2, 4)
    const year = value.substring(4, 8)
    const formattedValue = value ? `${day}/${month}/${year}` : ''

    setValue(formattedValue)
  }

  return (
    <>
      <div style={style} className={className}>
        <NumberFormat
          id={name}
          {...field}
          name={name}
          fit="fluid"
          format="##/##/####"
          inputMode="numeric"
          error={meta.error && meta.touched}
          customInput={Field.Input}
          onValueChange={handleChange}
          disabled={disabled}
          {...props}
        />
      </div>

      <ErrorMessage name={name}>
        {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
      </ErrorMessage>
    </>
  )
}

export default DateInput

DateInput.propTypes = {
  format: PropTypes.string,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  validate: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
}
