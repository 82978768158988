import { parseHtml } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import getTitleId from 'kiss/utils/get-title-id'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledTitle = styled.h1`
  &,
  &.kiss-h1 {
    font: var(--font-title-large);
    font-family: var(--font-family-antiqueolive) !important;
  }

  &.kiss-h2 {
    font: var(--font-title-small);
  }

  &.kiss-h3 {
    font: var(--font-subtitle-large);
  }

  &.kiss-h4 {
    font: var(--font-subtitle-medium);
  }
`

const RichTextTitle = ({ level = 1, text, className, fromRichText }) => {
  if (!text || text === '') return null
  const tag = `h${level || '1'}`

  return (
    <StyledTitle
      as={tag}
      className={classNames(className, { [`kiss-${tag}`]: !fromRichText })}
      id={getTitleId(text)}
    >
      {parseHtml(text, { sanitize: false })}
    </StyledTitle>
  )
}

RichTextTitle.propTypes = {
  text: PropTypes.string.isRequired,
  level: PropTypes.oneOf([...new Array(4)].map((_, i) => i + 1)),
}

export default RichTextTitle
