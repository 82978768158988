import {
  Button,
  CarouselNext,
  Container,
  mq,
  PartnerCard,
  pxToRem,
  Text,
  Title,
  useLazyObserver,
} from '@kisskissbankbank/kitten'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { getPartnerships } from '../redux'
import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'
import { PARTNERS, getRouteFor } from '../../../routes/redux'

const StyledWrapper = styled.section`
  position: relative;
  background-color: var(--color-danger-100);
  margin-top: ${pxToRem(-50)};

  & > * {
    position: relative;
    z-index: 2;
  }

  .kiss-Homepage__partner__container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: ${pxToRem(20)};
    padding: ${pxToRem(100)} 0 ${pxToRem(100)} 0;
  }

  .k-CarouselNext__inner {
    // Worst fucking fix ever.
    width: calc(100vw - 2 * var(--computed-padding));
  }

  .k-CarouselNext--showOtherPages .k-CarouselNext__inner {
    --container-padding: ${pxToRem(50)};

    @media ${mq.tabletAndDesktop} {
      --container-padding: ${pxToRem(150)};
    }
  }

  .k-CarouselNext__page {
    height: 100% !important;
  }
`

const PartnerCardComponent = ({ item, shouldLoadImages = false }) => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)

  return (
    <PartnerCard
      key={`ProjectCard${item.item}`}
      imageProps={{
        src: shouldLoadImages ? item.image.url : assetFor('default_16x10.svg'),
        alt: 'partner',
        loading: 'lazy',
      }}
      stretch
    >
      <PartnerCard.Avatar
        imageProps={{
          src: item.logo.url,
          alt: '',
        }}
      />
      <PartnerCard.Line>
        <Text size="small" weight="500">
          {item.shortDesc}
        </Text>
        <PartnerCard.Sticker>{item.tags}</PartnerCard.Sticker>
        <Text
          tag="a"
          href={item.externalUrl}
          size="small"
          className="k-u-link-font1 k-u-weight-600 k-u-cursor-pointer k-u-margin-none"
        >
          {t('new_home.partnerships.know_more')}
        </Text>
      </PartnerCard.Line>
    </PartnerCard>
  )
}

const PartnerCarousel = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  return (
    <StyledWrapper>
      <div className="kiss-Homepage__partner__container">
        <Title
          tag="h2"
          family="antiqueolive"
          className="k-u-margin-none k-u-margin-vertical-double@xs-down k-u-align-center"
          modifier="tertiary"
        >
          {t('new_home.partnerships.title', { parseHtml: true })}
        </Title>

        <CarouselBlock />

        <Text weight="600" size="large" className="k-u-align-center">
          {t('new_home.partnerships.are_you_business', { parseHtml: true })}
        </Text>
        <Button as="a" href={routeFor(PARTNERS)} modifier="beryllium">
          {t('new_home.partnerships.become_partner')}
        </Button>
      </div>
    </StyledWrapper>
  )
}

const CarouselBlock = () => {
  const partnerships = useSelector(getPartnerships)
  const [navProps, setNavProps] = useState({})
  const { viewportIsXSOrLess, viewportIsSOrLess, viewportIsMOrLess } =
    useMediaQuery()
  const lazyElement = useRef(null)
  const shouldLoadImages = useLazyObserver(lazyElement)
  if (partnerships.length === 0) return null

  return (
    <div>
      <div ref={lazyElement} />
      <Container className="k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-end k-u-flex-gap-double">
        <CarouselNext.Navigation
          {...navProps}
          buttonProps={{ size: 'small' }}
        />
      </Container>
      <CarouselNext
        baseGap={30}
        cycle
        itemsPerPage={viewportIsSOrLess ? 1 : 3}
        itemMinWidth={viewportIsSOrLess ? 200 : 300}
        viewportIsXSOrLess={viewportIsXSOrLess}
        viewportIsMOrLess={viewportIsMOrLess}
        navigationPropsGetter={setNavProps}
        showOtherPages
        shadowSize={viewportIsSOrLess ? 15 : 30}
      >
        {partnerships.map((item, index) => (
          <PartnerCardComponent
            item={item}
            key={index}
            shouldLoadImages={shouldLoadImages}
          />
        ))}
      </CarouselNext>
    </div>
  )
}

export default PartnerCarousel
