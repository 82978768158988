const config = {
  development: {
    responsiveImageHandler: {
      host: 'http://localhost:3900/dev/resize',
      allowedServers: ['localhost:9000', '0.0.0.0:9000'],
    },
    google: {
      placeApiKey: 'AIzaSyCzXg72OfjO0QQcEAuN6jF7VNn_P5MQUqQ',
    },
    recaptcha: {
      siteKey: '6Ld-UBIaAAAAAANhF3Yj3YEu8gpXr8iWemYJP3sl',
    },
    sentry: {
      dsn: 'https://b262cc635223478287b6c54522433ab4@o389116.ingest.sentry.io/5226872',
    },
    stonly: {
      id: 'ae5a0c93-5a45-11eb-accc-0ad3430e55ea',
    },
    algolia: {
      appId: 'SY84X5OU46',
      apiKey: 'b728e77d114fc3666fc4f3a83d5e1bc7',
      indexName: 'kisskissbankbank_projects_development',
      indexOrder: 'kisskissbankbank_orders_development',
    },
    embedly: {
      key: '8b7d8dd6504d41af9a77662672aabc2a',
    },
    facebook: {
      appId: '825036660868954',
    },
    matrix: {
      host: 'http://localhost:8008',
    },
    manager: {
      host: 'http://localhost:3100',
    },
  },
  test: {
    responsiveImageHandler: {
      host: 'https://testDistribHost.cloudfront.net',
      allowedServers: ['testDistribAllowedServers1.cloudfront.net'],
    },
    google: {
      placeApiKey: 'AIzaSyCzXg72OfjO0QQcEAuN6jF7VNn_P5MQUqQ',
    },
    recaptcha: {
      siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    },
    sentry: {
      dsn: 'https://b262cc635223478287b6c54522433ab4@o389116.ingest.sentry.io/5226872',
    },
    stonly: {
      id: 'ae5a0c93-5a45-11eb-accc-0ad3430e55ea',
    },
    algolia: {
      appId: 'SY84X5OU46',
      apiKey: 'b728e77d114fc3666fc4f3a83d5e1bc7',
      indexName: 'kisskissbankbank_projects_development',
      indexOrder: 'kisskissbankbank_orders_development',
    },
    embedly: {
      key: '8b7d8dd6504d41af9a77662672aabc2a',
    },
    facebook: {
      appId: '825036660868954',
    },
    matrix: {
      host: 'https://matrix.kisskissbankbank.com',
    },
    manager: {
      host: 'https://manager.kisskissbankbank.com/',
    },
  },
  staging: {
    responsiveImageHandler: {
      host: 'https://d1qghcxhkmxp5u.cloudfront.net',
      allowedServers: ['d1qghcxhkmxp5u.cloudfront.net'],
    },
    google: {
      placeApiKey: 'AIzaSyCzXg72OfjO0QQcEAuN6jF7VNn_P5MQUqQ',
    },
    recaptcha: {
      siteKey: '6LfHG7cqAAAAABeyCweJax07gUBkorkfCyagiSYL',
    },
    sentry: {
      dsn: 'https://f29d1a6fb06c6f5edb3c521d15845a9a@o389116.ingest.us.sentry.io/4507268172152832',
    },
    stonly: {
      id: 'ae5a0c93-5a45-11eb-accc-0ad3430e55ea',
    },
    algolia: {
      appId: 'SY84X5OU46',
      apiKey: '767396bf57b385987b63ade74e60b270',
      indexName: 'kisskissbankbank_projects_staging',
      indexOrder: 'kisskissbankbank_orders_staging',
    },
    embedly: {
      key: '8b7d8dd6504d41af9a77662672aabc2a',
    },
    facebook: {
      appId: '825036660868954',
    },
    matrix: {
      host: 'https://matrix.kisskissbankbank.com',
    },
    manager: {
      host: 'https://manager-staging.kisskissbankbank.com/',
    },
  },
  production: {
    responsiveImageHandler: {
      host: 'https://djxmmoom3injc.cloudfront.net',
      allowedServers: ['djxmmoom3injc.cloudfront.net'],
    },
    google: {
      placeApiKey: 'AIzaSyCzXg72OfjO0QQcEAuN6jF7VNn_P5MQUqQ',
    },
    recaptcha: {
      siteKey: '6LfHG7cqAAAAABeyCweJax07gUBkorkfCyagiSYL',
    },
    sentry: {
      dsn: 'https://56014e36302b1fab1b7bbc7b462e5cb6@o389116.ingest.us.sentry.io/4507334846709760',
    },
    stonly: {
      id: 'ae5a0c93-5a45-11eb-accc-0ad3430e55ea',
    },
    algolia: {
      appId: 'SY84X5OU46',
      apiKey: '71eb78bfa7db6f5e4e8c1addb2e149ee',
      indexName: 'kisskissbankbank_projects_production',
      indexOrder: 'kisskissbankbank_orders_production',
    },
    embedly: {
      key: '8b7d8dd6504d41af9a77662672aabc2a',
    },
    facebook: {
      appId: '116032865102674',
    },
    matrix: {
      host: 'https://matrix.kisskissbankbank.com',
    },
    manager: {
      host: 'https://manager.kisskissbankbank.com/',
    },
  },
}

export default config
