import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, SIGN_IN } from 'kiss/routes/redux'
import { getReturnTo, signIn } from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { addFlowConnectionFlag } from 'kiss/utils/url/query-string-helper'

import PropTypes from 'prop-types'
import * as qs from 'neoqs'

import {
  Button,
  domElementHelper,
  LockOutlineIcon,
  pxToRem,
  Text,
} from '@kisskissbankbank/kitten'
import { Formik } from 'formik'
import { addSuccessAlert } from 'kiss/app/alerts/redux'
import EnabledAfterMount from 'kiss/app/common/enabled-after-mount'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import Checkbox from 'kiss/components/formik/checkbox'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import NewPassword from 'kiss/components/formik/new-password'
import RgpdModal from 'kiss/components/modals/rgpd'
import ExplainLine from './explain-line'

import { object, string } from 'yup'
import ResetPasswordLink from './ask-reset-password/link'
import styled from 'styled-components'

const ExplainBlock = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  padding: ${pxToRem(20)};
  margin-top: ${pxToRem(20)};
  background-color: var(--color-grey-200);
  border-radius: var(--border-radius-m);
`

const AutoSelectInput = ({ name, disabled, isFocused, ...props }) => {
  useEffect(() => {
    if (disabled) return
    return isFocused && !!document && document?.getElementById(name)?.focus()
  }, [disabled])

  if (name === 'password') {
    return <NewPassword id={name} name={name} disabled={disabled} {...props} />
  }
  return <SimpleText id={name} name={name} disabled={disabled} {...props} />
}

const SignIn = ({ nextUrl = null, afterRedirect = () => {}, projectId }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const returnToPath = useSelector(getReturnTo)
  const routeFor = useSelector(getRouteFor)
  const returnTo = nextUrl || returnToPath
  const { search } = useLocation()
  const history = useHistory()
  const searchObject = qs.parse(search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (!domElementHelper.canUseDom()) return
    if (!searchObject?.email && !searchObject?.new_password) return
    if (!document.getElementById('password')) return
    if (searchObject?.new_password) {
      setTimeout(() => {
        dispatch(
          addSuccessAlert(t('authenticate.update_password.submit_success')),
        )
      }, 100)
    }
  }, [])

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      initialValues={{
        email: searchObject?.email || '',
        password: '',
        rememberMe: true,
        gaCode: undefined,
        invitationToken: undefined,
      }}
      validationSchema={object().shape({
        email: string()
          .email(t('login.form.email.error.invalid'))
          .required(t('login.form.email.error.missing')),
        password: string().required(t('login.form.password.error.missing')),
      })}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        dispatch(
          signIn({
            email: values.email.trim(),
            password: values.password,
            rememberMe: !!values.rememberMe,
            gaCode: values.gaCode,
            invitationToken: searchObject?.invitation_token,
            projectId,
          }),
        )
          .then(async (response) => {
            if (!response.signIn) {
              setFieldError(
                'password',
                t('authenticate.login.error', {
                  parseHtml: true,
                }),
              )
              return Promise.reject()
            }

            afterRedirect()
            if (searchObject?.origin === 'manager') {
              RoutingHelper.redirect(
                `${config[APP_ENV].manager.host}/${searchObject?.resolvedUrl}`,
              )
            } else {
              RoutingHelper.redirect(addFlowConnectionFlag(returnTo))
            }
          })
          .catch((error) => {
            setSubmitting(false)
            if (error && error.code === 'redirect_to_check_ga') {
              history.push(
                routeFor(
                  `${SIGN_IN}?${qs.stringify({
                    ...searchObject,
                    gaCode: true,
                  })}`,
                ),
              )
            }
          })
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <EnabledAfterMount>
          {(enabled) => (
            <form onSubmit={handleSubmit}>
              <div className="k-u-margin-bottom-double">
                <Label htmlFor="email">
                  {t('authenticate.login.form.email.with.star.label')}
                </Label>

                <AutoSelectInput
                  name="email"
                  disabled={!enabled}
                  normalize={(value) => value.trim()}
                  type="email"
                  autoComplete="email"
                  isFocused={!!searchObject?.new_password}
                />
              </div>

              <div className="k-u-margin-bottom-triple">
                <div className="k-u-flex k-u-flex-justifyContent-sb">
                  <Label htmlFor="password">
                    {t('login.form.password.label')}
                  </Label>
                  <ResetPasswordLink email={values.email} />
                </div>
                <AutoSelectInput
                  name="password"
                  iconLabel="show password"
                  hiddenIconLabel="hidden password"
                  placeholder={t('login.form.password.placeholder')}
                  disabled={!enabled}
                  aria-describedby="security-notification"
                  autoComplete="new-password"
                  isFocused={!searchObject?.new_password}
                />

                <Checkbox
                  name="rememberMe"
                  className="k-u-margin-bottom-quadruple k-u-margin-top-double"
                  id="rememberMe"
                  text={t('login.form.checkbox.remember')}
                  disabled={!enabled}
                />
              </div>
              {searchObject?.gaCode && (
                <div className="k-u-margin-bottom-triple">
                  <Text
                    size="micro"
                    weight="500"
                    color="error"
                    tag="label"
                    htmlFor="gaCode"
                  >
                    {t('authenticate.login.gaCode.label')}
                  </Text>
                  <SimpleText
                    name="gaCode"
                    inputMode="numeric"
                    placeholder="••••••"
                    autoComplete="one-time-code"
                  />
                </div>
              )}

              {isSubmitting && <SubmitLoader fit="fluid" size="medium" />}
              {!isSubmitting && (
                <Button
                  type="submit"
                  modifier="helium"
                  data-test-id="submit"
                  fit="fluid"
                  disabled={!enabled}
                >
                  {t('authenticate.login.form.submit')}
                </Button>
              )}
              <ExplainBlock>
                <ExplainLine icon={LockOutlineIcon}>
                  <ExplainLine.Text size="micro">
                    <RgpdModal regularText textColor="var(--color-grey-700)" />
                  </ExplainLine.Text>
                </ExplainLine>
              </ExplainBlock>
            </form>
          )}
        </EnabledAfterMount>
      )}
    </Formik>
  )
}

SignIn.propTypes = {
  nextUrl: PropTypes.string,
  afterRedirect: PropTypes.func,
  projectId: PropTypes.string,
}

export default SignIn
