import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import CommentForm from 'kiss/modules/project-page/common/news/CommentForm'
import Comment from 'kiss/modules/project-page/common/news/Comment'

const Replies = ({
  replies = [],
  commentUuid = null,
  isShowingForm = false,
  hideForm,
  replyOnClick = () => {},
  newsId,
  closeForm = undefined,
}) => {
  const t = useTranslation()

  if (replies.length === 0 && !isShowingForm) return null

  return (
    <>
      {replies.map((props) => (
        <Comment
          key={`${props.uuid}-${props.text}`}
          className="k-u-margin-top-noneHalf"
          isSecondary
          parentCommentId={commentUuid}
          replyOnClick={replyOnClick}
          newsId={newsId}
          {...props}
        />
      ))}

      {isShowingForm && (
        <CommentForm
          className="k-u-margin-top-noneHalf"
          id={`reply-form-${commentUuid}`}
          commentButtonLabel={t('project.comments.reply')}
          commentUuid={commentUuid}
          newsId={newsId}
          onSubmit={hideForm}
          onClose={closeForm}
        />
      )}
    </>
  )
}

Replies.propTypes = {
  replies: PropTypes.array,
  commentUuid: PropTypes.string,
  isShowingForm: PropTypes.bool,
  replyOnClick: PropTypes.func,
  newsId: PropTypes.string.isRequired,
  closeForm: PropTypes.func,
}

export default Replies
