import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'
import { ErrorMessage, Field as FormikField } from 'formik'
import {
  domElementHelper,
  Field,
  LocationInput,
  Marger,
} from '@kisskissbankbank/kitten'

const Location = ({
  field,
  form,
  placeholder = undefined,
  disabled = false,
  onSelect = () => {},
  onChange = () => {},
  onBlur,
  inputRef,
  customInputValue,
  ...others
}) => {
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false)
  const fieldName = prop(field.name)

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        if (domElementHelper.canUseDom() && window.google.maps.places) {
          setGoogleApiLoaded(true)
          clearInterval(intervalId)
        }
      } catch (_e) {
        return null
      }
    }, 500)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  if (!googleApiLoaded) return null

  return (
    <>
      <Marger bottom="1">
        <LocationInput
          name={field.name}
          ref={inputRef}
          defaultValue={field.value}
          error={fieldName(form.errors) && fieldName(form.touched)}
          onSelect={onSelect}
          onChange={onChange}
          inputProps={{
            onBlur,
            placeholder,
            disabled,
            id: field.name,
            'data-test-id': field.name,
            autoComplete: 'kisskiss-prevent-autocomplete',
          }}
          customInputValue={customInputValue}
          searchOptions={others?.searchOptions}
        />
      </Marger>
      <Marger top="1">
        <ErrorMessage name={field.name}>
          {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
        </ErrorMessage>
      </Marger>
    </>
  )
}

const FormikLocation = (props) => {
  return <FormikField {...props} component={Location} />
}

FormikLocation.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  inputRef: PropTypes.object,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
}

FormikLocation.defaultProps = {
  defaultValue: undefined,
  validate: () => {},
}

export default FormikLocation
