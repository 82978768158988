import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import { getAssetFor } from 'kiss/assets/redux'

import {
  Button,
  Container,
  CONTAINER_PADDING,
  Grid,
  GridCol,
  pxToRem,
  ScreenConfig,
  Title,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'

import classNames from 'classnames'
import styled from 'styled-components'

const StyledImage = styled.img`
  width: 100%;
  @media (min-width: ${ScreenConfig.S.min}px) and (max-width: ${ScreenConfig.M
      .max}px) {
    margin-right: -${pxToRem(CONTAINER_PADDING)};
    width: calc(100% + ${pxToRem(CONTAINER_PADDING)});
  }
`

const READ_MORE_LINK =
  'https://welcome.kisskissbankbank.com/ulule-et-kisskissbankbank/'

const UluleSection = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)
  return (
    <Container>
      <Grid>
        <GridCol col-l="9" offset-l="1">
          <TitleWithStroke
            tag="h2"
            family="antiqueolive"
            modifier="tertiary"
            noMargin
            className="k-u-align-center@xs-down"
            cssColor="var(--color-primary-500)"
          >
            {t('fund_my_project.ulule.title', {
              parseHtml: true,
            })}
          </TitleWithStroke>
        </GridCol>
        <GridCol
          offset-l="1"
          col-s="5"
          offset-s="1"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div className={classNames('k-u-margin-top-triple')}>
            <Title
              tag="h3"
              family="antiqueolive"
              modifier="tertiary"
              style={{ lineHeight: 1.15 }}
            >
              {t('fund_my_project.ulule.subtitle')}
            </Title>
          </div>
          <div
            className={classNames(
              'k-u-align-center@xs-down',
              'k-u-align-left@s-up',
              'k-u-margin-top-quadruple',
            )}
          >
            <Button
              size="large"
              modifier="helium"
              as="a"
              href={READ_MORE_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('fund_my_project.ulule.button')}
            </Button>
          </div>
        </GridCol>
        <GridCol col-l="4" offset-l="1" col-s="6" offset-s="0">
          <StyledImage
            src={assetFor('illustrations/ulule/KissKiss_Ulule_figures.svg')}
            alt={'cc'}
          />
        </GridCol>
      </Grid>
    </Container>
  )
}

export default UluleSection
