import { Button } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { matchMediaQuery as matchMediaQuerySelector } from 'kiss/media-queries/redux'
import { showRewardsHalo } from 'kiss/modules/project-page/page-state/actions'
import {
  REWARDS_MOBILE_ID,
  REWARDS_TARGET_ID,
} from 'kiss/modules/project-page/page-state/redux'
import {
  getCurrentProjectRouteFor,
  isCountGoalType,
} from 'kiss/modules/project-page/page-state/selectors'
import { PROJECT_ROUTE } from 'kiss/routes/redux'
import { scrollTo } from 'kiss/utils/animation/scroll-to'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ContributeButton = ({ size = 'medium', ...others }) => {
  const currentProjectRouteFor = useSelector(getCurrentProjectRouteFor)
  const matchMediaQuery = useSelector(matchMediaQuerySelector)
  const projectIsCountGoalType = useSelector(isCountGoalType)
  const t = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const label = projectIsCountGoalType
    ? t('reward_card.count_goal_action')
    : t('reward_card.action')

  const handleClick = () => {
    if (matchMediaQuery('viewportIsMOrLess')) {
      scrollTo(document.getElementById(REWARDS_MOBILE_ID), 500, { offset: -80 })
    } else {
      history.push(currentProjectRouteFor(PROJECT_ROUTE))
      scrollTo(document.getElementById(REWARDS_TARGET_ID), 500, {
        offset: -100,
      })
      dispatch(showRewardsHalo())
    }
  }

  return (
    <Button
      data-test-id="contribute-button"
      modifier="helium"
      type="button"
      size={size}
      onClick={handleClick}
      onTouchEnd={handleClick}
      {...others}
    >
      {label}
    </Button>
  )
}

ContributeButton.propTypes = {
  size: PropTypes.string,
}

export default ContributeButton
