import { pxToRem } from '@kisskissbankbank/kitten'
import FacebookButton from 'kiss/components/social/facebook-button'
import LinkedinButton from 'kiss/components/social/linkedin-button'
import TwitterButton from 'kiss/components/social/twitter-button'
import {
  getOrganizationOrOwnerName,
  getProjectName,
  getProjectUrl,
} from 'kiss/modules/project-page/page-state/selectors'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import FollowButton from './follow-button'
import WidgetCardModal from './widget-card-modal'

const Social = ({
  withFollowButton = true,
  label = undefined,
  showWidget = true,
  ...props
}) => {
  const projectName = useSelector(getProjectName)
  const ownerName = useSelector(getOrganizationOrOwnerName)
  const projectUrl = useSelector(getProjectUrl)

  return (
    <StyledButtonContainer {...props}>
      {withFollowButton && <FollowButton />}
      {showWidget && <WidgetCardModal />}
      <FacebookButton sharedUrl={projectUrl} />
      <TwitterButton
        sharedUrl={projectUrl}
        projectName={projectName}
        ownerName={ownerName}
      />
      <LinkedinButton
        sharedUrl={projectUrl}
        projectName={projectName}
        ownerName={ownerName}
      />
    </StyledButtonContainer>
  )
}

Social.propTypes = {
  label: PropTypes.string,
  withFollowButton: PropTypes.bool,
  showWidget: PropTypes.bool,
}

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-top: ${pxToRem(15)};

    &:not(:last-child) {
      margin-right: ${pxToRem(15)};
    }
  }
`

export default Social
