import React from 'react'
import PropTypes from 'prop-types'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import classNames from 'classnames'
import { Button, VisuallyHidden } from '@kisskissbankbank/kitten'

const SubmitLoader = ({ size = 'large', className, ...props }) => {
  const t = useTranslation()

  return (
    <Button
      modifier="helium"
      size={size}
      aria-live="polite"
      as="div"
      {...props}
      className={classNames(
        'kiss-SubmitLoader',
        'k-u-cursor-not-allowed',
        className,
      )}
    >
      <LoadingAnimation color="var(--color-grey-000)" />
      <VisuallyHidden>{t('app.loading')}</VisuallyHidden>
    </Button>
  )
}
SubmitLoader.propTypes = {
  size: PropTypes.string,
}

export default SubmitLoader
