import { LinkedinButtonIcon } from '@kisskissbankbank/kitten'
import SocialClickHandler from 'kiss/components/social/social-click-handler'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import React from 'react'

const LinkedinButton = ({ sharedUrl, projectName, ownerName, style = {} }) => {
  const t = useTranslation()

  const buildLinkedinUrl = () => {
    const encodedUrl = encodeURIComponent(sharedUrl)
    const text = encodeURIComponent(
      t('project_details.social.share_msg', {
        project: projectName,
        owner: ownerName,
      }),
    )
    const title = encodeURIComponent(projectName)

    return (
      'https://www.linkedin.com/shareArticle' +
      `?url=${encodedUrl}` +
      `&title=${title}` +
      `&summary=${text}` +
      '&mini=true' +
      `&source=${encodedUrl}`
    )
  }

  return (
    <SocialClickHandler>
      {(handleClick) => {
        return (
          <LinkedinButtonIcon
            style={style}
            onClick={() => handleClick('LinkedIn', buildLinkedinUrl())}
            aria-label={t('app.sharing.share_on_linkedin')}
          />
        )
      }}
    </SocialClickHandler>
  )
}

LinkedinButton.propTypes = {
  sharedUrl: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default LinkedinButton
