import React from 'react'
import PropTypes from 'prop-types'
import { LoadingAnimation } from './loading-animation'
import styled, { css } from 'styled-components'
import { Paragraph, pxToRem } from '@kisskissbankbank/kitten'

const StyledParagraphContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ loaderPosition }) =>
    ['top', 'bottom'].includes(loaderPosition)
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
        `}
`

const StyledParagraph = styled(({ loaderPosition, ...props }) => (
  <Paragraph {...props} />
))`
  flex: 0 0 auto;
  order: 1;
  ${({ loaderPosition }) => `margin-${loaderPosition}: ${pxToRem(10)};`}
`

const StyledLoadingAnimation = styled(({ loaderPosition, ...props }) => (
  <LoadingAnimation {...props} />
))`
  flex: 0 0 auto;
  order: ${({ loaderPosition }) =>
    ['top', 'left'].includes(loaderPosition) ? 0 : 2};
`

export const LoaderWithParagraph = ({
  className,
  loaderPosition = 'left',
  loaderProps = {},
  paragraphProps = {},
  children = 'Loading',
  ...others
}) => (
  <StyledParagraphContainer loaderPosition={loaderPosition} {...others}>
    <StyledParagraph
      loaderPosition={loaderPosition}
      modifier="primary"
      noMargin
      {...paragraphProps}
    >
      {children}
    </StyledParagraph>

    <StyledLoadingAnimation loaderPosition={loaderPosition} {...loaderProps} />
  </StyledParagraphContainer>
)

LoaderWithParagraph.propTypes = {
  children: PropTypes.node,
  loaderPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  loaderProps: PropTypes.object,
  paragraphProps: PropTypes.object,
}
