import { DropdownPhoneSelect, Field } from '@kisskissbankbank/kitten'
import { ErrorMessage, useField } from 'formik'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

const Phone = ({ name, validate = () => null, label, ...others }) => {
  const assetFor = useSelector(getAssetFor)
  const t = useTranslation()
  const { locale } = useParams()
  const [field, meta, helper] = useField({ name, validate })
  return (
    <>
      <DropdownPhoneSelect
        id={field.name}
        hideLabel
        className="k-u-margin-top-single"
        highlightOptionBox
        normalizer={(value, country) => {
          if (
            country?.countryCode === '33' &&
            value.startsWith('0') &&
            value.length > 2
          ) {
            return value.substring(1)
          }
          return value
        }}
        locale={locale}
        value={field.value}
        error={meta.error && meta.touched}
        flagsUrl={assetFor('flags.png')}
        a11yStatusError={t('app.forms.select.is_error')}
        a11yStatusValid={t('app.forms.select.is_valid')}
        a11ySelectionMessageDisplayer={(value) => {
          t('app.forms.select.selected_message', { value: value })
        }}
        defaultCountry={locale === 'nl' ? 'be' : 'fr'}
        phoneProps={{
          preferredCountries: [
            'fr',
            'be',
            'lu',
            're',
            'gp',
            'mq',
            'pf',
            'nc',
            'gf',
            'yt',
          ],
        }}
        onChange={(value) => helper.setValue(value)}
        {...others}
      />
      <ErrorMessage name={name}>
        {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
      </ErrorMessage>
    </>
  )
}

Phone.propTypes = {
  validate: PropTypes.func,
}

export default Phone
