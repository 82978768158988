import { mq, pxToRem } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const StyledFilterWrapper = styled.div`
  box-sizing: border-box;
  overflow-y: scroll;
  margin-top: ${pxToRem(10)};
  position: absolute;
  z-index: ${Z_INDEX.global.filterModal};
  max-height: ${pxToRem(540)};
  padding: ${pxToRem(10)} ${pxToRem(20)} ${pxToRem(15)};
  left: 0;
  border-radius: var(--border-radius-m);
  background: var(--color-grey-000);
  box-shadow: rgba(0, 0, 0, 0.2) 0px ${pxToRem(10)} ${pxToRem(20)} 0px;
  min-width: 100%;

  @media ${mq.tablet} {
    min-width: ${pxToRem(500)};
  }
`

const Filter = ({ children, onFilterClose = () => {}, className }) => {
  const filterRef = useRef(null)

  useEffect(() => {
    if (!filterRef.current) return

    filterRef.current.classList.remove('kiss-FilterWrapper--isInvisible')
  }, [])

  const KEYCODE_ESC = 27

  const handleEscKeypress = (e) => {
    if (e.key === 'Escape' || e.keyCode === KEYCODE_ESC) {
      onFilterClose()
    }
  }
  const handleDocumentClick = (e) => {
    if (
      e.target
        ?.closest('button.kiss-FilterButton')
        ?.parentNode?.contains(filterRef.current)
    )
      return
    if (!filterRef.current) return
    if (filterRef.current.contains(e.target)) return

    onFilterClose()
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, { capture: true })
    document.addEventListener('keydown', handleEscKeypress, { capture: true })

    return () => {
      document.removeEventListener('click', handleDocumentClick, {
        capture: true,
      })
      document.removeEventListener('keydown', handleEscKeypress, {
        capture: true,
      })
    }
  }, [])

  return (
    <StyledFilterWrapper
      ref={filterRef}
      className={classNames(
        'kiss-FilterWrapper kiss-FilterWrapper--isInvisible',
        className,
      )}
    >
      {children}
    </StyledFilterWrapper>
  )
}

Filter.propTypes = {
  onFilterClose: PropTypes.func,
}

export default Filter
