import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { currentUserCanDeleteComment as currentUserCanDeleteCommentSelector } from '../../comments/redux'
import { pxToRem, Text } from '@kisskissbankbank/kitten'
import Dialog from 'kiss/app/modal/dialog'
import { getProjectSlug, isLogged } from '../../../page-state/selectors'
import { deleteComment } from '../redux'

const LinksWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: ${pxToRem(5)};
  margin-right: ${pxToRem(30)};
  gap: ${pxToRem(10)};

  .kiss-ProjectPage-Comments-ActionText {
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      :hover,
      :focus,
      :active {
        color: inherit !important;
      }
    }
  }
`

const ActionLinks = ({
  uuid,
  ownerSlug = null,
  replyOnClick = () => {},
  loading,
  onClickEdit,
}) => {
  const t = useTranslation()
  const isLoggedIn = useSelector(isLogged)
  const currentUserCanDeleteComment = useSelector(
    currentUserCanDeleteCommentSelector,
  )
  const dispatch = useDispatch()
  const slug = useSelector(getProjectSlug)

  if (!ownerSlug) return null
  if (!isLoggedIn) return null

  return (
    <LinksWrapper>
      <Text
        className="kiss-ProjectPage-Comments-ActionText k-u-reset-button k-u-link k-u-link-font1"
        weight="500"
        size="micro"
        type="button"
        tag="button"
        disabled={loading}
        onClick={replyOnClick}
      >
        {t('project.comments.reply')}
      </Text>

      {currentUserCanDeleteComment(ownerSlug) && (
        <Text
          className="kiss-ProjectPage-Comments-ActionText k-u-reset-button k-u-link k-u-link-font1"
          weight="500"
          size="micro"
          type="button"
          tag="button"
          disabled={loading}
          onClick={onClickEdit}
        >
          {t('project_comments.edit_comment_link')}
        </Text>
      )}

      {currentUserCanDeleteComment(ownerSlug) && (
        <Dialog
          size="large"
          title={t('project.comments.deleted_comment_modal.title')}
          primaryAction={(close) => {
            dispatch(deleteComment({ commentId: uuid, slug }))
            close()
          }}
          primaryActionText={t('project.comments.deleted_comment_modal.yes')}
          secondaryAction={(close) => close()}
          secondaryActionText={t('project.comments.deleted_comment_modal.no')}
        >
          <Text
            className="kiss-ProjectPage-Comments-ActionText k-u-reset-button k-u-link k-u-link-font1"
            weight="500"
            size="micro"
            type="button"
            tag="button"
            disabled={loading}
          >
            {t('project_comments.delete_comment_link')}
          </Text>
        </Dialog>
      )}
    </LinksWrapper>
  )
}

ActionLinks.propTypes = {
  uuid: PropTypes.string.isRequired,
  ownerSlug: PropTypes.string,
  replyOnClick: PropTypes.func,
}

export default ActionLinks
