import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Label from 'kiss/components/formik/label'
import Dropdown from 'kiss/components/formik/dropdown-select'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useFormikContext } from 'formik'
import { ArrowContainer, Cart, pxToRem, Text } from '@kisskissbankbank/kitten'
import { getDeliveryCountries } from 'kiss/modules/components/reward-modal/shipping'
import {
  getVariationsCount,
  getCartDeliveryAmount,
  getDeliveryCostCalculationMethod,
  getShippingAddressCountryCode,
} from 'kiss/modules/project-page/page-state/selectors'

const StyledArrowContainer = styled(ArrowContainer)`
  z-index: initial;
  display: flex;
  margin-top: ${pxToRem(20)};
`

const CartIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  border-radius: var(--border-radius-rounded);
  background-color: var(--color-primary-500);
  margin-right: ${pxToRem(10)};
`

const ShippingAddress = ({
  deliveryCountries,
  hideLabel,
  handleSelectChange = () => {},
}) => {
  const t = useTranslation()
  const variationsCount = useSelector(getVariationsCount)
  const cartDeliveryAmount = useSelector(getCartDeliveryAmount)
  const cartShippingAddressCountryCode = useSelector(
    getShippingAddressCountryCode,
  )
  const deliveryCostCalculationMethod = useSelector(
    getDeliveryCostCalculationMethod,
  )
  const { values, setFieldValue } = useFormikContext()
  const options = getDeliveryCountries(t, deliveryCountries)
  const [selectedOption, setSelectedOption] = useState(options[0])

  useEffect(() => {
    setFieldValue('countryCode', selectedOption.value, false)
  }, [])

  const onChange = (option) => {
    handleSelectChange()
    setSelectedOption(option)
  }

  const sameCountry = selectedOption.value === cartShippingAddressCountryCode

  const shippingAmount =
    (selectedOption.amount > cartDeliveryAmount.cents && sameCountry) ||
    !sameCountry
      ? selectedOption.amount
      : cartDeliveryAmount.cents

  return (
    <div className="k-u-margin-top-single">
      {!hideLabel && (
        <Label
          htmlFor="countryCode"
          label={t('project.modal.shipping.label')}
        />
      )}
      <Dropdown
        name="countryCode"
        value={selectedOption.value}
        validate={(value) => {
          if (!value && values.shipping === 'postal') {
            return t('app.forms.error.required')
          }
        }}
        options={options}
        aria-label={t('project.modal.shipping.placeholder')}
        aria-labelledBy="shipping-notification"
        onChange={onChange}
      />

      <StyledArrowContainer position="top" color="var(--color-primary-100)">
        <CartIconCircle>
          <Cart color="var(--color-grey-000)" width="17" height="16" />
        </CartIconCircle>
        <Text
          id="shipping-notification"
          tag="p"
          weight="400"
          color="font1"
          size="micro"
          lineHeight="normal"
        >
          {variationsCount > 0 && deliveryCostCalculationMethod === 'max'
            ? t('project.modal.shipping.information_with_amount', {
                amount: shippingAmount / 100,
              })
            : t('project.modal.shipping.information')}
        </Text>
      </StyledArrowContainer>
    </div>
  )
}

ShippingAddress.propTypes = {
  deliveryCountries: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func,
}

export default ShippingAddress
