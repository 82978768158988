import { domElementHelper } from '@kisskissbankbank/kitten'
import * as Sentry from '@sentry/react'
import config from 'kiss/config'

// Source: https://gist.github.com/impressiver/5092952
const ignoredErrorsConfig = {
  ignoreErrors: [
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
    'Script error.',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    "Can't find variable: PaymentAutofillConfig",
    "TypeError: 'get' on proxy: property 'javaEnabled' is a read-only and non-configurable data property",
  ],
  ignoreUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
}

export const initSentry = () => {
  if (!domElementHelper.canUseDom()) return
  if (APP_ENV === 'development') return
  if (APP_ENV === 'test') return

  Sentry.init({
    dsn: config[APP_ENV].sentry.dsn,
    release: APP_VERSION,
    environment: APP_ENV,
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
      }),
    ],
    ...ignoredErrorsConfig,
  })

  Sentry.setTag('team', 'frontend')
}

export const setSentryUserId = (id) => {
  Sentry.setUser({ id })
}

export const sentryCaptureException = (error, context) => {
  Sentry.captureException(error, context)
}
